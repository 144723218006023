import { Text, UnderlineButton } from "flicket-ui";

import { ReactNode, useState } from "react";
import EventsCalendarModal from "~components/calendar/calendar";
import {} from "~components/reservation/non-seated/non-seated.helpers";

export function MoreDatesButton({ children }: { children: ReactNode }) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <>
      <EventsCalendarModal
        isOpen={calendarOpen}
        onClose={() => setCalendarOpen(false)}
      />
      <UnderlineButton onClick={() => setCalendarOpen(true)} color="N800">
        {children}
      </UnderlineButton>
    </>
  );
}
