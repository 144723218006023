import { pick } from "@styled-system/props";
import { Box, SystemProps } from "flicket-ui";

interface CardProps extends SystemProps {
  children?: React.ReactNode;
}

export function Card(props: CardProps) {
  const { children, ...rest } = props;
  return (
    <Box
      borderRadius={"sm"}
      boxShadow={"container"}
      overflow="hidden"
      {...pick(rest)}
    >
      {children}
    </Box>
  );
}
