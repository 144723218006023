import { CompetitionFragmentFragment, CompetitionType } from "~graphql/sdk";
import {
  ActiveCompetitionsDocument,
  ActiveCompetitionByTypeDocument,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";

export function useActiveCompetitions(eventId?: string) {
  const queryMethods = useQuery(eventId ? ActiveCompetitionsDocument : null, {
    eventId,
  });

  return {
    activeCompetitions: queryMethods.data
      ?.activeCompetitions as CompetitionFragmentFragment[],
    ...queryMethods,
  };
}

export function useActiveCompetitionByType(
  competitionType: CompetitionType,
  eventId?: string
) {
  const queryMethods = useQuery(
    eventId ? ActiveCompetitionByTypeDocument : null,
    {
      input: {
        eventId,
        competitionType,
      },
    }
  );

  return {
    activeCompetition: queryMethods.data?.activeCompetitionByType,
    ...queryMethods,
  };
}
