import { omit, pick } from "@styled-system/props";
import { Box, Flex, SystemProps, Text } from "flicket-ui";
import Image from "~components/common/Image";
import { ExtendedFile } from "~graphql/sdk";
import { Card } from "./Card";

interface ContentProps {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

function Content(props: ContentProps) {
  const { title, footer = null, children } = props;

  const showFooterAsText = typeof footer === "string";

  return (
    <Flex style={{ gap: "32px" }} flexDirection={"column"} padding={4}>
      {title ? (
        <Text
          color="N800"
          fontSize={6}
          fontWeight={"extraBold"}
          textAlign={"center"}
        >
          {title}
        </Text>
      ) : null}

      {children}

      {showFooterAsText ? (
        <Text
          color="N700"
          fontWeight={"regular"}
          fontSize={2}
          textAlign={"center"}
        >
          {footer}
        </Text>
      ) : null}

      {!showFooterAsText ? footer : null}
    </Flex>
  );
}

interface ContentWithImageProps extends ContentProps {
  image?: ExtendedFile;
  imageAlt?: string;
}

export function CTACardWithImage(props: ContentWithImageProps) {
  const { image, imageAlt, children, ...rest } = props;
  return (
    <Box>
      {props.image ? <Image image={image} alt={imageAlt ?? ""} /> : null}
      <Content {...rest}>{children}</Content>
    </Box>
  );
}

interface EventCTACardProps extends SystemProps, ContentWithImageProps {
  children?: React.ReactNode;
}

export default function EventCTACard(props: EventCTACardProps) {
  return (
    <Card {...pick(props)}>
      <CTACardWithImage {...omit(props)} />
    </Card>
  );
}
