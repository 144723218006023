import { pick } from "@styled-system/props";
import { Box, SystemProps } from "flicket-ui";

interface EventDetailsSectionProps extends SystemProps {
  children: React.ReactNode;
}

export const CONTENT_WIDTH = "960px";

export default function Section(props: EventDetailsSectionProps) {
  const { children, ...rest } = props;
  return (
    <Box mx={"auto" as any} maxW={CONTENT_WIDTH} as="section" {...pick(rest)}>
      {children}
    </Box>
  );
}
