import { Box, Flex, Text, UnderlineButton } from "flicket-ui";
import {
  CompetitionFragmentFragment,
  VenueAddressFragment,
} from "~graphql/sdk";
import { formatEventDate } from "~lib/helpers/formatDate";

interface CompetitionCardFooterProps {
  competition: CompetitionFragmentFragment;
  venue: VenueAddressFragment;
}

export default function CompetitionCardFooter(
  props: CompetitionCardFooterProps
) {
  const endDateFormatted = formatEventDate(
    props.competition?.endDate,
    "datetime",
    {
      timeZone: props.venue?.timezone,
      locale: props.venue?.locale,
    }
  );

  const competitionClosesText = `Competition closes ${endDateFormatted}`;

  return (
    <Flex flexDirection={"column"} style={{ gap: "12px" }}>
      <Text
        color="N700"
        fontWeight={"regular"}
        fontSize={2}
        textAlign={"center"}
      >
        {competitionClosesText}
      </Text>
      <Box textAlign={"center"}>
        <UnderlineButton
          href="#competition-details"
          color="N800"
          fontSize={3}
          fontWeight={"regular"}
        >
          More info
        </UnderlineButton>
      </Box>
    </Flex>
  );
}
