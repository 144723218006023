import { Attributes, trace } from "@opentelemetry/api";
import { MouseEventHandler } from "react";
import { useOrganization } from "~hooks";
import getSessionId from "~lib/helpers/sessionId";
import { TRACER_NAME } from "~telemetry/FrontendTracer";
import { AttributeNames } from "~telemetry/enums/AttributeNames";

export type EventType = keyof HTMLElementEventMap;

interface Options {
  forwardToNest?: boolean;
  eventId?: string;
  attributes?: Attributes;
  pageName?: string;
}

export default function useCaptureClick(options: Options = {}) {
  const { organization } = useOrganization();
  const {
    forwardToNest,
    eventId,
    attributes: attributesFromInit,
    pageName: pageNameFromInit,
  } = options;

  return function captureClick<T>(
    handler: MouseEventHandler<T> | undefined,
    analyticsEventName: string,
    attributes: Attributes = {} // Attributes can be set when you call the hook OR when you call the function
  ) {
    return (event: React.MouseEvent<T, MouseEvent>) => {
      const eventType: EventType = "click";
      const sessionId = getSessionId();

      const interactionName = pageNameFromInit
        ? `${pageNameFromInit}.${analyticsEventName}`
        : analyticsEventName;

      const buttonSpan = trace.getTracer(TRACER_NAME).startSpan(eventType, {
        attributes: {
          [AttributeNames.FLICKET_USER_INTERACTION_TYPE]: eventType,
          [AttributeNames.FLICKET_USER_INTERACTION_NAME]: interactionName,
          [AttributeNames.FLICKET_SESSION_ID]: sessionId,
          [AttributeNames.FLICKET_EVENT_ID]: eventId,
          [AttributeNames.FLICKET_ORG_ID]: organization.id,
          [AttributeNames.FLICKET_FORWARD_TO_NEST]: forwardToNest,
          [AttributeNames.FLICKET_TIMEZONE]: new window.Intl.DateTimeFormat().resolvedOptions()
            .timeZone,
          [AttributeNames.HTTP_URL]:
            typeof window !== "undefined" ? window.location.href : undefined,
          ...attributesFromInit,
          ...attributes,
        },
      });
      buttonSpan.end();

      handler?.(event);
    };
  };
}
