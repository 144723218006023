import { useRouter } from "next/router";
import { useEffect } from "react";
import qs from "qs";

/**
 * Returns a navigate function that will perform a router push. Additionally returns the url that will be navigated to.
 */
export default function useGetTickets(eventId: string) {
  const router = useRouter();

  // This will handle cartRecovery=true and unlisted releases.
  const paramsObj = {
    ...router.query,
  };

  delete paramsObj.eventId;

  const queryParams = qs.stringify(paramsObj);

  const baseEventUrl = `/events/${eventId}/reservation`;
  const url = queryParams ? `${baseEventUrl}?${queryParams}` : baseEventUrl;

  // Some prefetching to improve page load performance
  useEffect(() => {
    void router.prefetch(url);
  }, [eventId]);

  const getTickets = () => {
    void router.push(url);
  };

  return { navigate: getTickets, url };
}
