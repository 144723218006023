import { notEmptyPredicate } from "~lib/type-utils/notEmptyPredicate";

export default function formatVenue(venue?: {
  name?: string;
  address?: {
    city?: string;
  };
}) {
  return `${[venue?.name, venue?.address?.city ? venue?.address?.city : null]
    .filter(notEmptyPredicate)
    .join(", ")}`;
}
