/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { Box, Flex, Text, Divider } from "flicket-ui";

import { Footer, PageContainer } from "~components";
import { useOrganization } from "~hooks";
import { EventQuery, LandingPage } from "~graphql/sdk";
import BackLink from "../events.BackLink";
import Section from "../events.Section";
import EventCTACard from "../events.EventCTACard";
import EventInformation, { EventSummary } from "../events.EventInformation";
import MainCTA from "../events.MainCTA";
import ReleaseCodes from "../events.ReleaseCodes";
import useShowReleaseCodes from "~features/events/hooks/useShowReleaseCodes";
import {
  PageContext,
  isOnsaleCompetitionContext,
  isOnsaleContext,
  isRegistrationCompetitionContext,
  isRegistrationContext,
} from "~features/events/hooks/useDetailsPageContext";
import CompetitionDetails from "../CompetitionDetails/CompetitionDetails";
import CompetitionCardFooter from "../CompetitionDetails/CompetitionCardFooter";
import { useEffect } from "react";

export type DesktopDetailProps = {
  event: EventQuery["event"];
  isSinglePage: boolean;
  pageContext: PageContext;
};

export const Desktop = ({
  pageContext,
  event,
  isSinglePage,
}: DesktopDetailProps) => {
  const {
    title,
    logo,
    backgroundImage,
    backgroundImageFullPage,
    season,
    enableReleaseCodes,
  } = event;

  const { organization, hasFeature } = useOrganization();

  const showReleaseCodes = useShowReleaseCodes(enableReleaseCodes);

  const calendarView = hasFeature("eventCalendarHomepage");

  return (
    <>
      <PageContainer
        backgroundImage={backgroundImage}
        backgroundImageFullPage={backgroundImageFullPage}
        bannerImage={logo}
        titleText={title}
        footer={<Footer />}
      >
        {!calendarView && (
          <BackLink
            isSinglePage={isSinglePage}
            tourId={
              organization.landingPage === LandingPage.Tours && season
                ? season.id
                : null
            }
          />
        )}
        {/* Title */}
        <Section mt={4} mb={6}>
          <Text
            as={"h1"}
            color="N800"
            fontWeight="extraBold"
            fontSize={9}
            lineHeight={"56px" as any}
          >
            {title}
          </Text>
        </Section>

        <Section mb={[8, 5]}>
          <Flex justifyContent={"space-between"} style={{ gap: "65px" }}>
            <Box flex={2} minWidth={0}>
              <Divider mb={5} />
              <EventSummary event={event} />
              <Divider my={[5, 4]} />
              <EventInformation event={event} />
            </Box>

            {/* Call to action card */}
            <Box flex={"0 0 370px"}>
              {isRegistrationContext(pageContext) ? (
                <EventCTACard title="Register to get notified when tickets are available!">
                  <MainCTA pageContext={pageContext} />
                </EventCTACard>
              ) : null}

              {isRegistrationCompetitionContext(pageContext) ||
              isOnsaleCompetitionContext(pageContext) ? (
                <EventCTACard
                  title={pageContext.competition?.title}
                  image={pageContext.competition?.prizes?.[0].image}
                  imageAlt={pageContext.competition?.title}
                  footer={
                    <CompetitionCardFooter
                      competition={pageContext.competition}
                      venue={event?.venue}
                    />
                  }
                >
                  <MainCTA pageContext={pageContext} />
                </EventCTACard>
              ) : null}

              {isOnsaleContext(pageContext) ? (
                <EventCTACard title="Secure your tickets today!">
                  <MainCTA pageContext={pageContext} />
                </EventCTACard>
              ) : null}

              {showReleaseCodes && (
                <EventCTACard title="Have an access code?" mt={4}>
                  <Box>
                    <ReleaseCodes event={event} />
                  </Box>
                </EventCTACard>
              )}
            </Box>
          </Flex>
        </Section>
        {isOnsaleCompetitionContext(pageContext) ||
        isRegistrationCompetitionContext(pageContext) ? (
          <CompetitionDetails
            competition={pageContext.competition}
            eventId={pageContext.event?.id}
          />
        ) : null}
      </PageContainer>
    </>
  );
};
