import { useMemo } from "react";
import { parsedAndGetDescriptionTextItems } from "~lib/helpers/formatMetaDescriptions";

const useDescriptionText = (description) => useMemo<string>(()=> {
    if(!description){
        return "";
    }
    const descriptions = parsedAndGetDescriptionTextItems({ description });
    return descriptions[0] ?? "";
}, [description]);

export default useDescriptionText;