import { Box, Text, UnderlineButton } from "flicket-ui";
import { useState } from "react";
import { createGlobalStyle } from "styled-components";
import { ContentModal } from "~components";
import { CompetitionFragmentFragment, CompetitionType } from "~graphql/sdk";
import { CONTENT_WIDTH } from "../events.Section";
import PrizeCard from "./PrizeCard";

interface CompetitionDetailsProps {
  eventId: string;
  competition?: CompetitionFragmentFragment;
}

// Set smooth scrolling for anchor links
const GlobalStyle = createGlobalStyle`
  html, body {
    scroll-behavior: smooth;
    scroll-margin-top: 64px;
  }

  /**
   * Disable smooth scrolling when users have prefers-reduced-motion enabled
   */
  @media screen and (prefers-reduced-motion: reduce) {
    html, body {
      scroll-behavior: auto;
    }
  }
`;

export default function CompetitionDetails(props: CompetitionDetailsProps) {
  const { eventId, competition } = props;
  const [activeModal, setActiveModal] = useState<"terms">();

  if (!competition) return null;

  const {
    title,
    description,
    prizes,
    termsAndConditions,
    competitionType,
  } = competition;

  const prizeCardUrl =
    competitionType === CompetitionType.Registration
      ? `/events/${eventId}/registration`
      : `/events/${eventId}/reservation`;

  return (
    <>
      <GlobalStyle />
      <Box
        as="section"
        background={"#F9F9FB" as any}
        pt={8}
        pb={6}
        my={0}
        mx={["-16px", "-16px", "-16px", "-16px", "-32px"] as any}
        px={[2, 2, 2, 4]}
        id="competition-details"
        css={{
          scrollPaddingTop: "64px",
        }}
      >
        <Box maxWidth={CONTENT_WIDTH} mx={"auto" as any}>
          {title || description ? (
            <Box mb={7}>
              {title ? (
                <Text
                  color="N800"
                  fontSize={6}
                  fontWeight={"extraBold"}
                  mb={description ? 3 : 0}
                >
                  {title}
                </Text>
              ) : null}
              {description ? (
                <Text color="N700" fontSize={3}>
                  {description}
                </Text>
              ) : null}
            </Box>
          ) : null}

          {!prizes || prizes.length === 0 ? null : (
            <Box mb={termsAndConditions ? 8 : 0}>
              <Text color="N800" fontSize={6} fontWeight={"extraBold"} mb={3}>
                Prizes up for grabs
              </Text>
              {prizes
                .sort((a, b) => a.prizeOrder - b.prizeOrder)
                .map((prize, index) => (
                  <PrizeCard
                    key={prize.id}
                    title={prize.title}
                    image={prize.image}
                    quantity={prize.quantity}
                    description={prize.description}
                    mb={index === prizes.length - 1 ? 0 : 4}
                    link={prizeCardUrl}
                  />
                ))}
            </Box>
          )}

          {termsAndConditions ? (
            <Box>
              <UnderlineButton onClick={() => setActiveModal("terms")}>
                <Text color="N700" fontWeight="regular" fontSize={4} ml={1}>
                  Competition Terms & Conditions
                </Text>
              </UnderlineButton>
            </Box>
          ) : null}
        </Box>
        <ContentModal
          isOpen={activeModal === "terms"}
          setIsOpen={() => setActiveModal(undefined)}
          title="Competition Terms & Conditions"
          richText={termsAndConditions}
        />
      </Box>
    </>
  );
}
