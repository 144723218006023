import { Container, Flex, Text, TransparentButton } from "flicket-ui";
import { Icon, Modal } from "~components";
import { EventQuery } from "~graphql/sdk";
import ReleaseCodes from "./events.ReleaseCodes";

interface ReleaseCodesModalProps {
  isOpen: boolean;
  onClose: () => void;
  event: EventQuery["event"];
}

export default function ReleaseCodesModal(props: ReleaseCodesModalProps) {
  const { isOpen, onClose, event } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container
        px={{ _: 0, md: 2 }}
        minHeight={{ _: "100%", md: "auto" }}
        innerProps={{
          p: { _: 2, md: 4 },
          minHeight: { _: "100%", md: "auto" },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxWidth: "800px",
        }}
      >
        <Flex
          alignItems="center"
          flexDirection={"row-reverse"}
          justifyContent={"space-between"}
        >
          <TransparentButton onClick={onClose}>
            <Icon icon="close" fontSize={6} />
          </TransparentButton>
        </Flex>
        <Flex
          mt={3}
          mb={7}
          flexDir={"column"}
          flex="1"
          justifyContent={"center"}
        >
          <Text
            fontWeight={"extraBold"}
            fontSize={6}
            color="N800"
            textAlign={"center"}
            mb={4}
          >
            Have an access code?
          </Text>
          <ReleaseCodes event={event} />
        </Flex>
      </Container>
    </Modal>
  );
}
