import { Box, Flex, Text } from "flicket-ui";
import { Event } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import { formatEventDateRange } from "~lib/helpers/formatDate";

export const MobileEventHeaderSinglePage = ({ event }: { event: Event }) => {
  const { hasFeature } = useOrganization();

  return (
    <Box
      display={{ _: "flex", md: "none" }}
      position="absolute"
      bottom="16px"
      left="16px"
      zIndex={2}
      maxW="calc(100% - 32px)"
      flexDir="column"
    >
      <Text
        fontSize={6}
        lineHeight="medium"
        fontWeight="heavy"
        color="white"
        mb="1/2"
        ellipsis
      >
        {event.title}
      </Text>
      <Flex
        mr={{ _: 2, md: 4 }}
        flexDir="column"
        minW={{ _: "80px", md: "auto" }}
      >
        <Text fontSize={1} lineHeight="normal" mb="1/4" color="white">
          {formatEventDateRange(event?.startDate, event?.endDate, {
            format: "basicLong",
            showStartTime: !hasFeature("hideEventTime"),
            timeZone: event?.venue?.timezone,
            locale: event?.venue?.locale,
          })}
        </Text>
      </Flex>
      <Text fontSize={1} lineHeight="normal" color="white">
        {event.venue?.name}, {event.venue?.address?.city}
      </Text>
    </Box>
  );
};
