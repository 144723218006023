type DescriptionChildren = { text: string; bold: boolean }[];
type DescriptionType = {
  type: string;
  children: DescriptionChildren;
};
type EventRaw = { title: string; description: string };

const flatMapToList = (p): DescriptionType => {
  if (p?.type?.includes("-list")) {
    return p?.children?.flatMap((item) => flatMapToList(item));
  }
  return p?.children?.flatMap((item) => item?.text || "");
};

export const parsedAndGetDescriptionTextItems = (
  event: Pick<EventRaw, "description">
): string[] =>
  !event.description
    ? ""
    : (JSON.parse(event.description) || [])
        .flatMap(flatMapToList)
        .filter(Boolean);

export const generateOgDescription = (event: {
  title: string;
  description: string;
}) => {
  // Pull out the first paragraph of the event description to use as the
  // SEO description text until we add a dedicated field for it.
  const descriptions = parsedAndGetDescriptionTextItems(event);
  return descriptions[0] || event.title || "";
};

export const generateSEODescription = (event: {
  title: string;
  description: string;
}) => {
  const descriptions = parsedAndGetDescriptionTextItems(event);
  return `Buy official ${event.title} tickets from Flicket. ${
    descriptions[0] || ""
  }. ${descriptions[1] || ""}`;
};
